<template>
  <b-card title="Lista de grupos ">
    <b-button 
      v-if="addInfo == 'userPage'"
      variant="gradient-primary mb-1" 
      @click="openRefModal"
    >
      Alterar grupos
    </b-button>
    <b-table
      v-if="searchedGroups"
      :items="searchedGroups"
      :fields="fields"
      striped
      responsive
      class="data-table"
      :key="k"
    >
      <template #cell(actions)="data">
        <div v-if="contextActions == 'manageMembers'">
          <feather-icon
            v-if="!checkAlready(data.item.id)"
            icon="PlusIcon"
            class="ml-2 b-fon text-primary pointer"
            @click="addUserOnGroup(data.item)"
          />
          <feather-icon
            v-else
            icon="MinusIcon"
            class="ml-2 b-fon text-danger pointer"
            @click="removeUserFromGroup(data.item)"
          />
        </div>
        <div v-if="contextActions == 'managePromotions'">
          <feather-icon
            v-if="!checkAlready(data.item.id)"
            icon="PlusIcon"
            class="ml-2 b-fon text-primary pointer"
            @click="addPromotionGroup(data.item)"
          />
          <feather-icon
            v-else
            icon="MinusIcon"
            class="ml-2 b-fon text-danger pointer"
            @click="removePromotionGroup(data.item)"
          />
        </div>
        <div v-if="contextActions == 'manageGroup'">
          <router-link :to="{ path: '/group-single/'+ data.item.id}">
            <feather-icon
              icon="EyeIcon"
              class="ml-2 b-fon text-primary pointer"
            />
          </router-link>
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'

import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _
  },
  props:['searchedGroups', 'contextActions', 'addInfo', 'customFields'],
  data() {
    return {
      fields: ['id', 'name','members_count'],
      k:0,
      localInfo:null,
    }
  },
  apollo:{
    
  },
  watch:{
    addInfo: function(){
      this.localInfo = this.addInfo;
    },
  },
  methods:{
    checkAlready(id){
      if(this.localInfo != null && this.localInfo.length >0){
        return this.localInfo.filter(a => a.id == id).length
      }
    },
    addUserOnGroup(item){
      item.members_count++;
      this.localInfo = this.localInfo.concat(item)
      this.$emit('addUserOnGroup', {'g_id': item.id, 'g_arr' : this.localInfo})
      this.k++;
    },
    removeUserFromGroup(item){
      item.members_count--;
      this.localInfo = this.localInfo.filter(r => r.id != item.id)
      this.$emit('removeUserFromGroup',{'g_id': item.id, 'g_arr' : this.localInfo})
      this.k++;
    },
    addPromotionGroup(item){
      if(this.localInfo == null){
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : [item]})
      }else{
        this.localInfo = this.localInfo.concat(item)
        this.$emit('addPromotionGroup', {'g_id': item.id, 'g_arr' : this.localInfo})
      }
      this.k++;
    },
    removePromotionGroup(item){
      this.localInfo = this.localInfo.filter(r => r.id != item.id)
      this.$emit('removePromotionGroup',{'g_id': item.id, 'g_arr' : this.localInfo})
      this.k++;
    },
    markItem(e,l,b){
      if(e.id != this.idToMark){
        this.localInfo = this.localInfo.concat(e)
        e.selected = this.marker;
        this.k++;
      }
    },
    openRefModal(){
      this.$emit('showGroupModal',true)
    }

  },
  created(){
    if(this.contextActions != null){
      this.fields.push('actions')
    }
    this.localInfo = this.addInfo;
    // console.log(this.localInfo)
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  .b-fon{
    width: 25px;
    height: 25px;
  }
</style>